import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyBwuzB5On9pxtwxL9Yc4RBt4S8VNuhR3Yw",
  authDomain: "brandambassador-69f36.firebaseapp.com",
  databaseURL: "https://brandambassador-69f36.firebaseio.com",
  projectId: "brandambassador-69f36",
  storageBucket: "brandambassador-69f36.appspot.com",
  messagingSenderId: "666876834465",
  appId: "1:666876834465:web:7aefa17b553f79fb1bf6e0",
  measurementId: "G-YV5QFRCCXZ"
};


firebase.initializeApp(firebaseConfig)

export function upload(images, user, callback) {

  images.forEach(image => {
    image.progress = 0
  })

  images.forEach((image, i) => {
    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(`${user.handle}/${user.handle}-${image.file.name}`);
    const task = imageRef.put(image.file);
    task.on('state_changed', snapshot => {
      image.progress = snapshot.bytesTransferred / snapshot.totalBytes * 100
      callback(images.reduce(((acc, cur) => acc + cur.progress), 0) / images.length)
    })
    task.then((img) => {
      img.ref.getDownloadURL().then(url => {
        firebase.firestore().collection('submissions').add({
          fullPath: img.metadata.fullPath,
          timestamp: img.metadata.updated,
          resolution: image.res,
          submitter: user,
          url
        })
      })
    })

  })

}

export function getImages(cursor = 0) {
  return new Promise((res, rej) => {
    firebase.firestore().collection("submissions").orderBy("timestamp", "desc").get().then(col => {
      res(col.docs.map(doc => doc.data()))
    })
  })
}

export class imagePagination {
  constructor(props) {
    this.last = null;
    this.query = firebase.firestore().collection('submission').orderBy('timestamp', 'desc').limit(10);
  }

  async get() {
    if (this.last) {
      await this.query.startAfter(this.last).get().then(col => {
        return col.docs.map(doc => doc.data())
      })
    } else {
      await this.query.get().then(col => {
        this.last = col.docs[col.docs.length - 1]
        return col.docs.map(doc => doc.data())
      })

    }
  }


}

export function getRowsByHandle(handle) {
  return new Promise(res => {
    firebase.firestore().collection('submissions').where('submitter.handle', '==', handle).get().then(q => {
      res(q.docs.map(doc => doc.data()))
    })
  })
}