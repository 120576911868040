import React, { useEffect, useState } from 'react'
import { Container, Table, TableRow, TableCell, TableBody, TableHead, TablePagination, TableFooter, Grid, makeStyles, TextField, InputAdornment } from '@material-ui/core'
import { getImages, getRowsByHandle, imagePagination } from '../firebase'
import { useLocation } from 'react-router-dom'
import { Search } from '@material-ui/icons'











export function Admin(props) {
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const { hash } = useLocation();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [delay, setDelay] = useState()

  useEffect(() => {
    if (hash === "#lillie") {
      setLoading(true)
      clearTimeout(delay)
      setDelay(setTimeout(() => {
        if (search === "") {
          getImages(rows).then(setRows).then(() => setLoading(false))
        } else if (search !== "") {
          getRowsByHandle(search).then(r => {
            setRows(r)
            setLoading(false)
            setPage(0)
          })
        }
      }, 1000))
    }
  }, [search])

  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }

  const getRows = () => {
    return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  }


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }



  const c = useStyles()
  return (//TODO -- still gets images
    hash === "#lillie" &&
    <Container className={c.container}>
      <Grid container direction="column">
        <Grid item className={c.searchGrid}>
          <TextField
            onChange={e => setSearch(e.target.value)}
            value={search}
            label="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Image</TableCell>
                <TableCell>Resolution</TableCell>
                <TableCell colSpan={2}>Handle</TableCell>
                <TableCell>Timestamp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length && !loading ? getRows().map(row => (
                <Row key={row.fullPath} data={row} />
              ))
                :
                <TableRow><TableCell colSpan={5}>{loading ? "Loading" : "No Results"}</TableCell></TableRow>
              }
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50, 75, 100]}
            rowsPerPage={rowsPerPage}
            count={rows.length}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            component='div'
          />
        </Grid>
      </Grid>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: "40px"
  },
  searchGrid: {
    alignSelf: 'flex-end'
  }
}))


function Row({ data }) {
  return (
    <TableRow>
      <TableCell>
        <img src={data.url} alt="pic" style={{ height: "150px" }} />
      </TableCell>
      <TableCell>{data.resolution}</TableCell>
      <TableCell>
        {data.submitter.pic ?
          <img alt="propic" src={data.submitter.pic} style={{ height: "80px" }} />
          :
          "No pic"
        }
      </TableCell>
      <TableCell>
        <center>
          {data.submitter.fullName || ""}<br />
          @{data.submitter.handle || data.submitter.username}
        </center>
      </TableCell>
      <TableCell>{parseTime(data.timestamp)}</TableCell>
    </TableRow>
  )
}









export function parseTime(timestamp) {
  const time = new Date(timestamp);

  return time.toDateString()
}