import React, { useState, useEffect, useRef, Fragment } from 'react';
import '../App.css';
import { Container, CssBaseline, Paper, makeStyles, TextField, Button, Typography, Grid, List, ListItem, ListItemText, colors, IconButton, LinearProgress, InputAdornment, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { Cancel, AlternateEmail, ImageSearch, Pets, } from "@material-ui/icons"
import { upload } from '../firebase'











export default function SubmissionFormView() {
  const [user, setUser] = useState("");
  const [images, setImages] = useState([]);
  const [progress, setProgress] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);



  function handleUpload() {
    setProgress(0);
    upload(images, { handle: user }, setProgress);
  }

  useEffect(() => {
    if (progress === 100) {
      setDialogOpen(true);
    }
  }, [progress])


  const cleanUp = () => {
    setUser("");
    setProgress(null);
    setImages([]);
  }




  const c = useStyles()
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper className={c.paper}>
        <Grid container spacing={3} direction="column" justify="center">

          <Grid item onClick={() => setDialogOpen(true)}>
            <img alt="pack-logo" src="https://cdn.shopify.com/s/files/1/1649/7215/files/fb_logo-1024px_134x134_crop_center@2x.png?v=1596572296" />
          </Grid>

          <Grid item>
            <Typography component="h2" variant="h6">
              Brand Ambassador Image Submission
            </Typography>
          </Grid>

          <Grid item>
            <List >

              <VerifyHandleView user={user} setUser={setUser} disabled={progress !== null} />

              <FileUploadView images={images} setImages={setImages} disabled={progress !== null} />

            </List>
          </Grid>

          <Grid item hidden={images.length === 0 || !user} >
            {!progress ?
              <Button variant="contained" disabled={progress === 0 || !!progress} fullWidth onClick={handleUpload} className={c.submit}>Submit</Button>
              :
              progress < 100 ?
                <div>
                  <LinearProgress variant="determinate" value={progress || 0} />
                </div>
                :
                "Complete!"
            }
          </Grid>

        </Grid>
      </Paper>
      <Dialog open={dialogOpen} onClose={() => { setDialogOpen(false); cleanUp()}} >
        <DialogTitle><center>Success! <br />Thanks {user}!</center></DialogTitle>
      </Dialog>
    </Container>
  )
}


function VerifyHandleView({ user, setUser, disabled }) {



  const c = useStyles()
  return (
    <Grid container style={{ backgroundColor: "#2BBDFF" }}>
      <ListItem className={c.listItem} >
        <ListItemText primary="Instagram Handle" />

      </ListItem>

      <TextField
        disabled={disabled}
        variant="outlined"
        fullWidth
        value={user}
        onChange={({ target }) => setUser(target.value)}
        autoFocus
        style={{ margin: "0 20px 20px 20px", backgroundColor: "#87c2dc" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start"><AlternateEmail /></InputAdornment>
          )
        }}
      />
    </Grid>
  )
}



function FileUploadView({ images, setImages, disabled }) {
  const fileInput = useRef(null)


  function handleSelection({ target }) {
    const imageFiles = []
    for (let i = 0; i < target.files.length; i++) {
      const img = new Image();
      const data = {
        file: target.files[i]
      }
      img.onload = function () {
        data.src = this.src
        data.res = `${this.width}x${this.height}`
        imageFiles.push(data)
        setImages(images.concat(imageFiles))

      }
      img.src = URL.createObjectURL(target.files[i])
    }
    target.value = null
  }


  function removeImage(image) {
    setImages(images => images.filter(i => i !== image)) //images.splice(images.findIndex((i) => i.src === image.src), 1))
  }

  const c = useStyles()
  return (
    <Fragment>

      <ListItem className={c.listItem}>
        <ListItemText primary="Upload Photos" />
        <IconButton onClick={() => fileInput.current.click()} >
          <input disabled={disabled} type="file" accept="image/*" multiple style={{ display: 'none' }} onChange={handleSelection} ref={fileInput} />
          <ImageSearch />
        </IconButton>
      </ListItem>

      <Grid justify="space-evenly" container style={{ minHeight: 100, backgroundColor: '#2BBDFF' }}>
        {images.map((image, i) => (
          <Grid item key={i}>
            <div style={{ position: 'relative' }}>
              <img alt="" className={c.image} src={image.src} />
              <IconButton disabled={disabled} onClick={() => removeImage(image)} style={{ position: "absolute", top: "-24px", right: "-24px" }} >
                <Cancel />
              </IconButton>
            </div>
          </Grid>
        ))}
        <Grid item>
          {images.length === 0 && <IconButton onClick={() => fileInput.current.click()}><Pets /></IconButton>}
        </Grid>
      </Grid>

    </Fragment>
  )
}






const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  listItem: {
    backgroundColor: "#2BBDFF",
    height: "80px"
  },
  content: {
    padding: theme.spacing(2),
    backgroundColor: "#2BBDFF",
    borderBottom: "solid 2px"
  },
  submit: {
    backgroundColor: colors.blue[900],
    color: "white"
  },
  image: {
    width: "100px",

  },

}));



export function parseTime(timestamp) {
  const time = new Date(timestamp);

  return time.toDateString()
}

