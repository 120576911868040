import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Admin } from './views/adminView';
import SubmissionFormView from './views/SubmissionFormView';

function App() {


  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <div className="App">
            <SubmissionFormView />
          </div>
        </Route>
        <Route exact path="/admin" component={Admin} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;



